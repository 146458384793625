import React from "react";
import FooterLogo from "../../Assets/logos-white.svg";
import F from "../../Assets/f.svg";
import T from "../../Assets/T.svg";
import L from "../../Assets/L.svg";
import I from "../../Assets/I.svg";
import { Divider } from "@mui/material";
import { Link as ScrollLink } from "react-scroll";

const Footer = ({ varient }) => {
  return (
    <footer
      className={`st-site-footer st-sticky-footer st-dynamic-bg ${
        varient ? varient : ""
      }`}
      style={{ background: "#091E52" }}
    >
      <div className="st-main-footer">
        <div className="container">
          <div className="row">
            <div className="st-footer-widget">
              <div className="st-text-field">
                <div className="row">
                  <div className="col-md-6 social">
                    <img
                      src={FooterLogo}
                      alt="image"
                      className="st-footer-logo"
                    />
                  </div>
                  <div className="col-md-6 d-flex justify-content-md-end gap-3">
                    <a
                      href="https://www.facebook.com/profile.php?id=61556427729473"
                      className="social-icon"
                    >
                      <img src={F} />
                    </a>
                    <a
                      href="https://twitter.com/PalFlock"
                      className="social-icon"
                    >
                      <img src={T} />
                    </a>
                    {/* <a href="#" className="social-icon">
                      <img src={L} />
                    </a> */}
                    <a
                      href="https://www.instagram.com/palflock?igsh=bzA4Zmcyd3phM2Fk&utm_source=qr"
                      className="social-icon"
                    >
                      <img src={I} />
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <Divider
              sx={{
                background: "#466ED0",
                width: "98.5%",
                display: "flex",
                margin: "auto",
                marginBottom: "50px",
              }}
            />
            <div className="row">
              <div className="col-lg-5 social">
                <h2 className="st-footer-widget-title">Explore</h2>
                <ul className="st-footer-contact-list st-mp0">
                  <li>
                    <ScrollLink
                      to="features"
                      spy={true}
                      duration={500}
                      offset={-80}
                      // onClick={() => setMobileToggle(false)}
                    >
                      Features
                    </ScrollLink>
                  </li>
                  <li>
                    <ScrollLink
                      to="gallery"
                      spy={true}
                      duration={500}
                      offset={-80}
                      // onClick={() => setMobileToggle(false)}
                    >
                      Gallery
                    </ScrollLink>
                  </li>
                  <li>
                    <ScrollLink
                      to="testimonial"
                      spy={true}
                      duration={500}
                      offset={-80}
                      // onClick={() => setMobileToggle(false)}
                    >
                      Testimonials
                    </ScrollLink>
                  </li>
                  <li>
                    <ScrollLink
                      to="faqs"
                      spy={true}
                      duration={500}
                      offset={-80}
                      // onClick={() => setMobileToggle(false)}
                    >
                      FAQs
                    </ScrollLink>
                  </li>
                </ul>
              </div>

              <div className="col-lg-4 social">
                <h2 className="st-footer-widget-title">Utility Pages</h2>
                <ul className="st-footer-contact-list st-mp0">
                  <li>
                    <a
                      target="_blank"
                      href="https://www.palflock.com/privacy-policy.html"
                    >
                      Privacy Policy
                    </a>
                  </li>
                  <li>
                    <a
                      target="_blank"
                      href="https://www.palflock.com/terms-of-use.html"
                    >
                      Terms of Use
                    </a>
                  </li>
                </ul>
              </div>
              <div className="col-lg-3 social">
                <h2 className="st-footer-widget-title">Contact Us</h2>
                <ul className="st-footer-contact-list st-mp0">
                  <li>
                    <span>Mail :</span> support@palflock.com
                  </li>
                  <li>
                    <span>Phone :</span> (+352)661661562
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div
          className="st-footer-contact-list st-mp0"
          style={{
            display: "flex",
            justifyContent: "center",
            margin: "auto",
            paddingTop: "15px",
            paddingBottom: "10px",
          }}
        >
          &#169; 2024, PalFlock | All rights reserved.
        </div>
      </div>
    </footer>
  );
};
export default Footer;
