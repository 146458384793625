import React from "react";
import HeroSlider from "../Testimonials/HeroSlider";
import playStore from "../../Assets/PlayStore.svg";
import appStore from "../../Assets/AppStore.svg";
const Hero = () => {
  return (
    <section
      id="home"
      className="st-hero-wrap st-gray-bg st-dynamic-bg overflow-hidden st-fixed-bg gradient-hero"
    >
      <div className="st-hero st-style1 hero-right">
        <div className="container">
          <div className="st-hero-text">
            <div className="st-height-b40 st-height-lg-b40" />
            <h1 className="st-hero-title cd-headline slide">
              Find a Pal today <br />
              <span className="cd-words-wrapper"></span>
            </h1>
            <div className="st-hero-subtitle">
              PalFlock will help you connect with like-minded people for shared{" "}
              <br />
              adventures and unforgettable experiences.
            </div>
            <div className="st-hero-btn">
              <img
                typeof="image/svg+xml"
                src={playStore}
                alt="image"
                style={{ marginRight: "20px" }}
                className="playstoreRes"
              />
              <img src={appStore} alt="image" className="appStore" />
            </div>
          </div>
        </div>
      </div>
      <HeroSlider />
    </section>
  );
};

export default Hero;
