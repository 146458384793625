import React, { useRef, useState } from "react";
import SectionHeading from "../SectionHeading/SectionHeading";
import { Button, InputAdornment, TextField } from "@mui/material";
import name from "../../Assets/name.svg";
import email from "../../Assets/email.svg";
import phone from "../../Assets/phone.svg";
import note from "../../Assets/note.svg";
import noteline from "../../Assets/noteline.svg";
import emailjs from "@emailjs/browser";
import { useSnackbar } from "notistack";

const ContactUs = () => {
  // Contact Form submition
  const form = useRef();
  const { enqueueSnackbar } = useSnackbar();

  const [formData, setFormData] = useState({
    user_name: "",
    user_email: "",
    user_number: "",
    message: "",
  });

  const [formErrors, setFormErrors] = useState({
    user_name: "",
    user_email: "",
    user_number: "",
    message: "",
  });
  const validateForm = () => {
    const errors = {};

    if (!formData.user_name.trim()) {
      errors.user_name = "Name is required!";
    }

    if (!formData.user_email.trim()) {
      errors.user_email = "Email is required!";
    } else if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(formData.user_email)) {
      errors.user_email = "Invalid email address";
    }

    if (!formData.message.trim()) {
      errors.message = "Message is required!";
    }

    setFormErrors(errors);
    return Object.keys(errors).length === 0;
  };

  const sendEmail = (e) => {
    e.preventDefault();

    if (validateForm()) {
      emailjs
        .sendForm("service_zijcadf", "template_npay9vc", form.current, {
          publicKey: "lhHDr6KezM6ciPvlK",
        })
        .then(
          () => {
            // form.current.reset();
            setFormData({
              user_name: "",
              user_email: "",
              user_number: "",
              message: "",
            });
            setFormErrors({
              user_name: "",
              user_email: "",
              user_number: "",
              message: "",
            });
            enqueueSnackbar("The mail has been sent", {
              variant: "success",
              anchorOrigin: {
                vertical: "top",
                horizontal: "center",
              },
            });
            console.log("SUCCESS!");
          },
          (error) => {
            enqueueSnackbar("Failed to send email. Please try again later.", {
              variant: "error",
              anchorOrigin: {
                vertical: "top",
                horizontal: "center",
              },
            });
            console.log("FAILED...", error.text);
          }
        );
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
    setFormErrors({
      ...formErrors,
      [name]: "",
    });
  };
  return (
    <section id="contactus" className="sections">
      <SectionHeading
        title="Contact Us"
        subTitle="We love hearing from you! Our dedicated team is here to assist you with<br /> any inquiries you may have."
      />
      <div className="container">
        <div className="row">
          <div className="col-lg-10 offset-lg-1">
            <div className="accordian-res">
              <form ref={form} onSubmit={sendEmail}>
                <div className="first-3-input">
                  <TextField
                    fullWidth
                    margin="normal"
                    size="small"
                    type="text"
                    name="user_name"
                    value={formData.user_name}
                    onChange={handleChange}
                    error={!!formErrors.user_name}
                    helperText={formErrors.user_name}
                    placeholder="Name"
                    variant="outlined"
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <img
                            src={name}
                            alt="image"
                            style={{ paddingRight: "5px" }}
                          />
                        </InputAdornment>
                      ),
                    }}
                  />
                  <TextField
                    fullWidth
                    margin="normal"
                    size="small"
                    type="email"
                    name="user_email"
                    value={formData.user_email}
                    onChange={handleChange}
                    error={!!formErrors.user_email}
                    helperText={formErrors.user_email}
                    placeholder="Email"
                    variant="outlined"
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <img
                            src={email}
                            alt="image"
                            style={{ paddingRight: "5px" }}
                          />
                        </InputAdornment>
                      ),
                    }}
                  />
                  <TextField
                    fullWidth
                    margin="normal"
                    size="small"
                    type="text"
                    name="user_number"
                    value={formData.user_number}
                    onChange={handleChange}
                    placeholder="Phone"
                    variant="outlined"
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <img
                            src={phone}
                            alt="image"
                            style={{ paddingRight: "5px" }}
                          />
                        </InputAdornment>
                      ),
                    }}
                  />
                </div>

                <div className="last-input">
                  <TextField
                    style={{ position: "relative" }}
                    fullWidth
                    multiline
                    rows={4}
                    margin="normal"
                    size="small"
                    type="text"
                    name="message"
                    value={formData.message}
                    onChange={handleChange}
                    error={!!formErrors.message}
                    helperText={formErrors.message}
                    placeholder="Any Note For Us"
                    variant="outlined"
                    InputLabelProps={{
                      sx: {
                        position: "absolute",
                        top: 0,
                        left: 15,
                        transform: "translateY(50%)",
                      },
                    }}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment
                          position="start"
                          sx={{ position: "absolute", top: 27, left: 15 }}
                        >
                          <img
                            src={note}
                            alt="image"
                            style={{ paddingRight: "5px" }}
                          />
                        </InputAdornment>
                      ),
                      endAdornment: (
                        <InputAdornment
                          position="end"
                          sx={{ position: "absolute", bottom: 10, right: 2 }}
                        >
                          <img src={noteline} alt="image" />
                        </InputAdornment>
                      ),
                    }}
                  />
                </div>
                <Button
                  style={{ marginTop: "15px" }}
                  fullWidth
                  type="submit"
                  variant="contained"
                  sx={{
                    background: "#032780",
                    border: "none",
                    height: "56px",
                    color: "#ffffff",
                    margin: "auto",
                    justifyContent: "center",
                    display: "flex",
                    alignItems: "center",
                    "&:hover": {
                      backgroundColor: "#001958",
                      boxShadow: "none",
                    },
                  }}
                >
                  SUBMIT
                </Button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ContactUs;
